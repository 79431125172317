import createDebug from 'debug';

import { bootstrap } from 'web/src/core/bootstrap';
import { createPrefetchProducer } from 'web/src/modules/core/apps/main/prefetch/createPrefetchProducer';

const debug = createDebug('nuxt');
debug('TODO: define/redefine vite public dir');
if (!process.env.VUE_APP_BUNDLER_NUXT && typeof window.__webpack_public_path__ === 'string') {
  // eslint-disable-next-line sonarjs/no-implicit-global
  __webpack_public_path__ = window.__webpack_public_path__;
}

export async function init(): Promise<void> {
  await bootstrap();
  const prefetchProducer = createPrefetchProducer();
  return import(
    /* webpackMode: "lazy" */
    'web/src/modules/core/apps/main/startApp'
  ).then(({ startApp }) => startApp(prefetchProducer)).catch((error) => console.error(error));
}

void init();
