import { getBuiltinConfig } from '@leon-hub/service-locator-env';

function getLocalStorageTheme(): string | null {
  try {
    return localStorage.getItem('theme');
  } catch {
    return null;
  }
}

export function readPageTheme(): string | null {
  const storageTheme = getLocalStorageTheme();
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return storageTheme;
  }
  const { theme } = getBuiltinConfig();
  return storageTheme ?? theme.current ?? theme.default;
}
