import { configureCordovaServiceLocator, prepareApp, whenCordovaReady } from '@leon-hub/cordova';
import { bootstrapEnvironment } from '@leon-hub/plugins';

import { useBaseApiClient, useGraphqlClient } from '@core/app-rest-client';

import { createBaseApiClient } from 'web/src/core/app/createBaseApiClient';
import { createGraphqlClient } from 'web/src/core/app/createGraphqlClient';
import { configureWebServiceLocator } from 'web/src/modules/core/ioc';
import { configureSsrServiceLocator } from 'web/src/modules/core/ioc/configureSsrServiceLocator';
import providePolyfills from 'web/src/utils/providePolyfills';
import { readPageTheme } from 'web/src/utils/readPageTheme';

export async function bootstrap(): Promise<void> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    await whenCordovaReady();
    configureCordovaServiceLocator();
    bootstrapEnvironment();
    await prepareApp();
  } else if (process.env.VUE_APP_RENDERING_CSR) {
    if (process.env.VUE_APP_BUNDLER_NUXT) {
      if (process.env.VUE_APP_RENDERING_SSR) {
        configureSsrServiceLocator();
      } else {
        configureWebServiceLocator();
      }
    } else {
      providePolyfills();
      configureWebServiceLocator();
      bootstrapEnvironment();
    }
  }
  bootstrapThemeClasses();
  useBaseApiClient(createBaseApiClient);
  useGraphqlClient(createGraphqlClient);
}

// Fix return from deposit case (access to cookies is not available in the template due to redirect).
function bootstrapThemeClasses() {
  const theme = readPageTheme();
  if (theme) {
    const el = document.documentElement;
    const className = `app__theme--${theme.toLowerCase()}`;
    if (el.className.includes('app__theme')) {
      el.className = el.className.replace(/app__theme--\w+/, className);
    } else {
      el.classList.add(className);
    }
  }
}
